.login-header {
  height: 80px;
  background: @white-color;
  > .bd {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    justify-content: space-between;
    .info {
      font-size: 16px;
      letter-spacing: 4px;
    }
    .brand-img {
      width: 183px;
      height: 77px;
    }
    .brand-info {
      // margin-left: 10px;
      display: flex;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
      }
      .tip-1 {
        font-size: 16px;
        color: var(--ying-txt-color);
      }
      .tip-2 {
        font-size: 16px;
        color: var(--ying-primary-color);
      }
      .devide-line {
        width: 1px;
        height: 14px;
        background: var(--ying-border-color);
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
}

@white-color: #FFFFFF;